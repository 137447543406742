<div class="sm:hidden"><router-outlet></router-outlet></div>
<!-- <div *ngIf="true" class="px-5 pt-10 sm:hidden">
  <div class="bg-white shadow-md shadow-main-grey border-2 py-5 px-2.5">
    <div class="flex items-center justify-center">
      <img src="../assets/icon/error.svg" alt="" />
    </div>
    <h1 class="text-center text-main-text text-2xl mt-10">
      Campaign not available this time
    </h1>
    <p class="text-center text-secondary-text mt-2.5">
      Please check back later for more information
    </p>
  </div>
</div> -->

<div
  class="max-sm:hidden text-center flex items-center justify-center h-screen"
>
  <div>This application allow to use on mobile only</div>
</div>
