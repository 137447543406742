import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RedeemService } from 'src/app/services/redeem.service';
import { ValidateBinNumberService } from 'src/app/services/validate-bin-number.service';
import { IQRCode, IRedeem } from 'src/app/shared/coupon.interface';

@Component({
  selector: 'app-redeem',
  templateUrl: './redeem.component.html',
  styleUrls: ['./redeem.component.scss'],
})
export class RedeemComponent {
  array: any = [];
  card = new FormControl(null, [Validators.required]);
  redeemBody!: IRedeem;
  errMsg = '';
  isRedemptionFull = false;
  isLoading = false;
  code!: IQRCode;

  constructor(
    private validate: ValidateBinNumberService,
    private redeemService: RedeemService,
    private router: Router
  ) {}

  updateInputValue(event: any) {
    const inputValue = event.target.value;

    // Remove non-numeric characters from the input
    const numericValue = inputValue.replace(/\D/g, '');

    let formattedValue: any = '';
    for (let i = 0; i < numericValue.length; i++) {
      if (i < 4) {
        formattedValue += numericValue[i];
      } else if (i === 4) {
        formattedValue += ' - ' + numericValue[i];
      } else if (i === 5) {
        formattedValue += numericValue[i];
      }
    }
    this.card.setValue(formattedValue);
  }

  onRedeem() {
    this.redeemService.redeemCoupon(this.redeemBody).subscribe({
      next: (data: any) => {
        if (data.data.msg !== 'Redeem success') {
          this.errMsg = '**Something went wrong, please try again later.';
        } else {
          this.code = JSON.parse(data.data.code);
        }
        this.isLoading = false;
      },
      error: (err) => {
        this.errMsg = '**Something went wrong, please try again later.';
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
        this.router.navigate(['coupon', this.code.QRCode]);
      },
    });
  }

  formSummary(card: any, id: string): IRedeem {
    const cardNumber = card.split(' - ').join('');
    const redeemBody: IRedeem = {
      card: cardNumber,
      couponId: id,
    };

    return redeemBody;
  }

  onSubmit() {
    this.errMsg = '';
    if (this.card.invalid) {
      this.card.markAllAsTouched();
    } else if (!this.validate.checkCardNumber(this.card.value)) {
      this.errMsg = '**Sorry, your card is not eligible for this promotion.';
    } else {
      this.isLoading = true;

      this.redeemService.getCoupon().subscribe({
        next: (data) => {
          const id = data.data._id as string;
          this.redeemBody = this.formSummary(this.card.value, id);
        },
        error: (err) => {
          console.log(err);
          this.isLoading = false;
          if (err.statusMessage === 'Coupon not found.') {
            this.errMsg =
              '**We apologize, but our promotion codes are no longer available for redemption';
            this.isRedemptionFull = true;
          } else {
            this.errMsg = '**Something went wrong, please try again later.';
          }
        },
        complete: () => {
          this.onRedeem();
        },
      });
    }
  }
}
