<div class="flex flex-col min-h-screen">
  <header class="flex flex-col text-white">
    <div class="bg-main-blue min-h-64 flex flex-col px-5 pt-20 pb-10 gap-5">
      <p class="text-sm font-visa-light">
        Exclusively for Visa Myanmar Cardholder
      </p>
      <div>
        <h1 class="text-2xl tracking-wider leading-normal font-visa-medium">
          Unlock THB 500 Discount at King Power
        </h1>
        <p class="opacity-80 text-xs font-visa-light mt-2">
          with Your Visa Card when you spend THB 5,000 or more
        </p>
      </div>
    </div>
    <div class="bg-main-yellow h-5"></div>
  </header>
  <div class="flex-grow">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
