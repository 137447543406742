import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IQRCode } from 'src/app/shared/coupon.interface';

@Component({
  selector: 'app-show-coupon',
  templateUrl: './show-coupon.component.html',
  styleUrls: ['./show-coupon.component.scss'],
})
export class ShowCouponComponent {
  qr!: string;
  code: IQRCode = { QRCode: '' };
  qrJson!: string;
  eanCode!: string;
  initEanCode = '00000';
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.qr = this.route.snapshot.params['QRCode'] as string;

    this.code.QRCode = this.qr;

    this.qrJson = JSON.stringify(this.code);
  }
}
