import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidateBinNumberService {
  constructor() {}
  private binnumber = [
    '405264',
    '405518',
    '405734',
    '406716',
    '407999',
    '408152',
    '408164',
    '416273',
    '419960',
    '421686',
    '422501',
    '427947',
    '453765',
    '455085',
    '460287',
    '462230',
    '468785',
    '483863',
    '483864',
    '483887',
    '484135',
  ];

  checkCardNumber(card: any) {
    const cardNumber = card.split(' - ').join('');

    const sixDigitCardNumber = cardNumber.slice(0, 6);

    return this.prepaidValidate(sixDigitCardNumber);
  }

  private prepaidValidate(cardNumber: any) {
    return this.binnumber.includes(cardNumber);
  }
}
