<div class="flex flex-col min-h-screen">
  <div class="flex-grow px-5 pt-10">
    <h1 class="text-2xl text-center font-visa-semibold text-main-text">
      Page not found
    </h1>
    <p class="text-center text-secondary-text text-sm mt-2.5">
      We can't seem to find the page you're looking for
    </p>
    <div class="mt-10">
      <button routerLink="/" class="bg-main-blue w-full text-white py-2.5 mt-2">
        Go Back To Redeem Page
      </button>
    </div>
  </div>
  <app-footer></app-footer>
</div>
