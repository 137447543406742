<div class="px-5 pt-10 pb-20">
  <div class="bg-white shadow-md shadow-main-grey border-2 py-5 px-2.5">
    <h1 class="text-center text-main-text">
      Simply fill in first 6 digits of your Visa card number
    </h1>
    <div class="mt-5">
      <img src="../assets/Card_Example.png" alt="card example" />
    </div>
    <div class="mt-10 w-full">
      <form enctype="multipart/form-data">
        <input
          type="text"
          class="border-2 border-gray-400 w-full p-2 px-4 font-visa-light"
          (input)="updateInputValue($event)"
          maxlength="9"
          minlength="9"
          [formControl]="card"
          [ngClass]="
            card.invalid && card.touched ? 'border-2 border-main-red' : ''
          "
        />
      </form>
      <div class="flex gap-1 mt-1.5 items-center text-secondary-text">
        <mat-icon class="scale-75">lock</mat-icon>
        <p class="text-xs">Your card details are encrypted and not stored</p>
      </div>
    </div>
    <div class="mt-10 w-full">
      <span class="text-main-red text-xs" *ngIf="card.invalid && card.touched"
        >Please fill your first 6 digits of your Visa card</span
      >
      <span class="text-main-red text-xs" *ngIf="errMsg">{{ errMsg }}</span>
      <button
        *ngIf="!isLoading && !isRedemptionFull"
        (click)="onSubmit()"
        class="bg-main-blue w-full text-white py-2.5 mt-2"
      >
        Redeem
      </button>
      <button
        *ngIf="isLoading && !isRedemptionFull"
        class="bg-main-blue w-full text-white py-2.5 mt-2"
      >
        Verifying...
      </button>
      <button
        *ngIf="!isLoading && isRedemptionFull"
        class="bg-[#A3A3A3] w-full text-white py-2.5 mt-2"
      >
        Fully Redemption
      </button>
    </div>
  </div>
  <div class="mt-10">
    <h1 class="text-xl text-main-text">Personal Data Protection Notice</h1>
    <p class="text-secondary-text mt-5 tracking-wide">
      For promotional verification purposes, we require the first six digits of
      your credit card number. This information is used solely to confirm your
      eligibility for our promotion and is not stored or processed for any other
      purpose. By entering your card details, you consent to this one-time
      verification. We maintain strict security standards to protect your
      personal information.
    </p>
  </div>
</div>
