import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IRedeem } from '../shared/coupon.interface';

@Injectable({
  providedIn: 'root',
})
export class RedeemService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private apiUrlWithPath = this.apiUrl + 'user/coupon';
  constructor(private http: HttpClient) {}

  getCoupon() {
    return this.http
      .get<any>(this.apiUrlWithPath)
      .pipe(catchError(this.handleError));
  }

  redeemCoupon(redeemBody: IRedeem) {
    return this.http
      .post(`${this.apiUrlWithPath}/redeem`, redeemBody)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error?.error.message);
    } else {
      console.error('service::error ', error?.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.statusMessage)) {
      errorMessage = error.error.statusMessage.toString();
    } else {
      errorMessage = error.error.statusMessage;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => error.error);
  }
}
