<div class="flex flex-col min-h-screen">
  <div class="flex-grow px-5 pt-10">
    <div class="bg-white shadow-md shadow-main-grey border-2 py-5 px-2.5">
      <h1 class="text-center text-main-text text-2xl">THB 500 Discount</h1>
      <p class="text-center text-sm text-secondary-text mt-2.5">
        For the minimum purchase of THB 5,000
      </p>
      <div class="mt-10 flex flex-col items-center justify-center">
        <qrcode
          [qrdata]="qrJson"
          [width]="220"
          [errorCorrectionLevel]="'L'"
          [margin]="0"
        ></qrcode>
        <!-- <p class="mt-5">{{ eanCode }}</p> -->
      </div>
      <div class="mt-10">
        <button
          routerLink="/"
          class="bg-main-blue w-full text-white py-2.5 mt-2"
        >
          Done
        </button>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
